import YouTubePlayer from 'youtube-player';
import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        videoId,
        muteVideo,
        fancyModal,
        player,
    }) {
        const el = document.getElementById(id);
        const cont = el.querySelector('div');

        let playerYouTube = null;
        let playerVimeo = null;

        if (player === 'youtube') {
            playerYouTube = YouTubePlayer(cont, {
                playerVars: {
                    rel: 0,
                    showinfo: 0,
                    ecver: 2,
                    mute: muteVideo,
                    fs: 0,
                },
            });
        } else {
            playerVimeo = new Vimeo.Player(cont, { // eslint-disable-line no-undef
                id: videoId, autoplay: 1, mute: 1, loop: 1,
            });
        }

        function handleCloseModal() {
            const play = () => {
                if (playerYouTube) {
                    playerYouTube.pauseVideo();
                } else if (playerVimeo) {
                    playerVimeo.pause();
                }
            };

            if (fancyModal) {
                // wait for video to fade out before pausing video
                setTimeout(() => play, 1000);
            } else {
                play();
            }
        }
        function handleStateChange(e) {
            if (e.data === 0) {
                emitEvent(events.closeModal, { clean: true });
            }
        }

        window.addEventListener(events.closeModal, handleCloseModal);
        if (playerYouTube) {
            playerYouTube.on('stateChange', handleStateChange);
        }

        if (fancyModal) {
            // play the video after it's faded in
            setTimeout(() => {
                if (playerYouTube) {
                    playerYouTube.loadVideoById(videoId);
                    playerYouTube.playVideo();
                } else {
                    playerVimeo.play();
                }
            }, 2500);
        } else if (playerYouTube) {
            playerYouTube.loadVideoById(videoId);
            playerYouTube.playVideo();
        } else {
            playerVimeo.play();
        }
    }
}
