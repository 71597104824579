const SCREEN_TIMOUT = 60000; // 30 seconds
const PAGE_TIMOUT = 60000 * 10; // 10 minutes

export default class {
    constructor({
        id,
        activeClass = 'is-active',
        pageRedirect,
    }) {
        const el = document.getElementById(id);

        let screenTimeout;
        let pageTimeout;

        function redirect() {
            window.location = pageRedirect;
        }
        function showScreensaver() {
            el.classList.add(activeClass);
        }
        function resetTimer() {
            clearTimeout(screenTimeout);
            if (pageRedirect) clearTimeout(pageTimeout);
            el.classList.remove(activeClass);
            screenTimeout = setTimeout(showScreensaver, SCREEN_TIMOUT);
            if (pageRedirect) pageTimeout = setTimeout(redirect, PAGE_TIMOUT);
        }

        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        document.onclick = resetTimer;
        document.onscroll = resetTimer;

        screenTimeout = setTimeout(showScreensaver, SCREEN_TIMOUT);
        if (pageRedirect) pageTimeout = setTimeout(redirect, PAGE_TIMOUT);
    }
}
