import Swiper from 'swiper';

export default class {
    constructor({
        id,
        loop = true,
    }) {
        const el = document.getElementById(id);
        const [prev, next] = el.querySelectorAll(`#${id} > button`);

        new Swiper(`#${id} .swiper-container`, { // eslint-disable-line no-new
            loop,
            slidesPerView: 'auto',
            preventClicks: true,
            preventClicksPropagation: true,
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
        });
    }
}
