import { recaptchaSiteKey } from '../config';
import { validateRecaptcha } from '../utilities/forms';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const submit = form.querySelector('[type="submit"]');

        let processing = false;

        async function handleSubmit(e) {
            e.preventDefault();

            if (processing) {
                return;
            }

            processing = true;
            submit.setAttribute('disabled', true);

            const isValid = await validateRecaptcha(recaptchaSiteKey);

            if (!isValid) {
                processing = false;
                submit.removeAttribute('disabled');

                console.error('Invalid reCAPTCHA request'); // eslint-disable-line no-console

                return;
            }

            form.submit();
        }

        form.addEventListener('submit', handleSubmit);
    }
}
