import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';

export default class {
    constructor({
        id,
        overlayHandle,
        closeHandle,
        contentHandle,
        activeClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const overlay = el.querySelector(overlayHandle);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only care about escape key
            if (e.keyCode !== 27) return;

            emitEvent(events.closeModal);
        }
        function handleOpenModal() {
            emitEvent(events.lockScroll);
            el.classList.add(activeClass);

            document.addEventListener('keyup', handleKeyup);
            // add the class to fade the video in, after the green overlay has swiped in
            setTimeout(() => {
                el.closest('.fancy-modal').classList.add('fancy-modal--loaded');
            }, 1500);
        }
        function handleCloseModal() {
            el.closest('.fancy-modal').classList.add('fancy-modal--close');
            setTimeout(() => {
                // clear the content to stop any videos from playing
                // the markup is already stored in the DOM so nothing will have to be redownloaded
                content.innerHTML = '';

                emitEvent(events.unlockScroll);
                el.classList.remove(activeClass);
                el.closest('.fancy-modal').classList.remove('fancy-modal--close');
                el.closest('.fancy-modal').classList.remove('fancy-modal--loaded');

                document.removeEventListener('keyup', handleKeyup);
            }, 2000);
        }
        function handleLoadFancyModal(e) {
            const {
                markup,
                full = 'false',
                position = 'center',
                size = 'lg',
                cb = null,
            } = e.detail;

            el.setAttribute('data-full', full);
            el.setAttribute('data-position', position);
            el.setAttribute('data-size', size);
            injectMarkup(content, markup);
            handleOpenModal();

            if (cb) { cb(content); }
        }
        function handleClick(e) {
            e.preventDefault();

            emitEvent(events.closeModal);
        }

        // Add event listeners
        window.addEventListener(events.openModal, handleOpenModal);
        window.addEventListener(events.closeModal, handleCloseModal);
        window.addEventListener(events.loadFancyModal, handleLoadFancyModal);
        close.addEventListener('click', handleClick);
        overlay.addEventListener('click', handleClick);
    }
}
