import { events, emitEvent } from '../utilities/custom-events';

const DATA_HANDLE = 'data-bd-id';

export default class {
    constructor({
        id,
        zoomToggleHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const zoomToggle = el.querySelector(zoomToggleHandle);
        const svg = el.querySelector('svg');
        const links = svg ? svg.querySelectorAll('a') : [];
        const properties = el.querySelectorAll(`li[${DATA_HANDLE}]`);

        function handleZoom() {
            const url = zoomToggle.getAttribute('data-zoom-image-url');
            const markup = zoomToggle.nextElementSibling.innerHTML;

            emitEvent(events.loadModal, { markup });
        }
        function handleMouseEnter(e) {
            const property = e.currentTarget;

            Array.from(links)
                .filter(link => link.id.includes(property.getAttribute(DATA_HANDLE)))
                .forEach(link => { link.classList.toggle(activeClass); });
        }
        function handleMouseLeave(e) {
            const property = e.currentTarget;

            Array.from(links)
                .filter(link => link.id.includes(property.getAttribute(DATA_HANDLE)))
                .forEach(link => { link.classList.toggle(activeClass); });
        }

        if (zoomToggle) {
            zoomToggle.addEventListener('click', handleZoom);
        }
        if (svg) {
            Array.from(properties).forEach(property => {
                property.addEventListener('mouseenter', handleMouseEnter);
                property.addEventListener('mouseleave', handleMouseLeave);
            });
        }

        // Initialize map
        emitEvent(events.populateMap, { properties });
    }
}
