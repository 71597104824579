import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        lightboxHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const lists = el.querySelectorAll('ul');
        const triggers = lists[0].querySelectorAll('img, button');
        const lightbox = el.querySelector(lightboxHandle);
        const img = lists[1].querySelectorAll('img');
        const galleries = lists[1].querySelectorAll('li');
        const controls = lightbox.querySelectorAll('button');
        const prev = controls[0];
        const next = controls[1];
        const close = controls[2];

        let activeImages = null;
        let activeIndex = 0;

        // Event handler functions
        function handlePrev() {
            activeImages[activeIndex].classList.remove(activeClass);
            activeIndex--;
            if (activeIndex < 0) {
                activeIndex = activeImages.length - 1;
            }
            activeImages[activeIndex].classList.add(activeClass);
        }
        function handleNext() {
            activeImages[activeIndex].classList.remove(activeClass);
            activeIndex++;
            if (activeIndex > activeImages.length - 1) {
                activeIndex = 0;
            }
            activeImages[activeIndex].classList.add(activeClass);
        }
        function handleClose() {
            for (var i = 0; i < galleries.length; i++) {
                galleries[i].classList.remove(activeClass);
            }
            for (var i = 0; i < img.length; i++) {
                img[i].classList.remove(activeClass);
            }
            lightbox.classList.remove(activeClass);
            activeImages = null;
            activeIndex = 0;
            emitEvent(events.hideOverlay);
        }
        function handleTrigger(e) {
            const galleryIndex = parseInt(e.currentTarget.getAttribute('data-gallery-index'), 10) || 0;

            // Activate lightbox
            lightbox.classList.add(activeClass);

            // Activate gallery corresponding to trigger
            const activeGallery = Array.from(galleries)
                .find((gallery, i) => gallery.classList.toggle(activeClass, i === galleryIndex));

            activeImages = activeGallery.querySelectorAll('img');
            activeImages[activeIndex].classList.add(activeClass);
            emitEvent(events.showOverlay);
        }

        // Add event listeners
        Array.from(triggers).forEach(trigger => {
            trigger.addEventListener('click', handleTrigger);
        });
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
        close.addEventListener('click', handleClose);
    }
}
