import { post } from '../utilities/router';

export default class {
    constructor({
        id,
        loadingClass,
        favoriteClass,
        autoFavorite,
    }) {
        const el = document.getElementById(id);
        const forms = el.querySelectorAll('form');

        // Helper functions
        function submitForm(form) {
            const formData = new FormData(form);

            function cb(res) {
                el.classList.remove(loadingClass);
                el.classList.toggle(favoriteClass, res.success);
            }

            el.classList.add(loadingClass);
            post({ formData, cb });
        }

        // Event handler functions
        function handleSubmit(e) {
            e.preventDefault();

            submitForm(e.currentTarget);
        }

        // Add event listeners
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', handleSubmit);
        });

        if (autoFavorite) {
            submitForm(forms[0]);
        }
    }
}
