export default class {
    constructor({
        id,
        activeClass,
        name,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const label = el.querySelector('label');
        const input = el.querySelector('input, select, textarea');
        const radios = el.querySelectorAll(`input[name="${name}"][type="radio"]`);

        // Event handler functions
        function handleInput(e) {
            // Toggle for invalid number inputs, focus events, and present values
            const toggle = (input.type === 'number' && !input.checkValidity())
        || e.type === 'focus'
        || input.value.trim();

            el.classList.toggle(activeClass, toggle);
        }
        function handleChange(e) {
            const toggle = e.currentTarget.value.trim();

            el.classList.toggle(activeClass, toggle);
        }

        // Add event listeners
        label.addEventListener('click', handleInput);
        input.addEventListener('focus', handleInput);
        input.addEventListener('blur', handleInput);
        input.addEventListener('input', handleInput);
        Array.from(radios).forEach(radio => {
            radio.addEventListener('change', handleChange);
        });

        // Initialize
        handleInput({ type: '' });
        Array.from(radios)
            .filter(radio => radio.checked)
            .forEach(radio => { handleChange({ currentTarget: radio }); });
    }
}
