const ANIMATION_SPEED = 500;
const SWAP_SPEED = 5000;

export default class {
    constructor({
        id,
        images,
    }) {
        const el = document.getElementById(id);
        const figures = el.querySelectorAll('aside > figure');

        const getRandomImage = () => {
            const usedImages = Array.from(figures).map(f => f.querySelector('img').src);
            const unusedImages = images.filter(([i]) => !usedImages.includes(i));

            return unusedImages[Math.floor(Math.random() * unusedImages.length)];
        };

        const swap = () => {
            const figure = figures[Math.floor(Math.random() * figures.length)];
            const image = figure.querySelector('img');
            const video = figure.querySelector('video');

            image.style.opacity = 0;
            image.onload = () => { image.style.opacity = 1; };
            video.style.opacity = 0;
            video.oncanplay = () => { video.style.opacity = 1; };

            setTimeout(() => {
                const [iSrc, vSrc] = getRandomImage();
                image.src = iSrc;
                video.src = vSrc;
            }, ANIMATION_SPEED);
            setTimeout(() => { swap(); }, SWAP_SPEED);
        };

        setTimeout(() => { swap(); }, SWAP_SPEED);
    }
}
