import Swiper from 'swiper';

export default class {
    constructor({
        id,
        contHandle,
    }) {
        // Params
        const el = document.getElementById(id);
        const swiper = el.querySelector('.image-carousel-alt__swiper');
        const prev = el.querySelector('.image-carousel-alt__swiper-prev');
        const next = el.querySelector('.image-carousel-alt__swiper-next');
        const pag = el.querySelector('.image-carousel-alt__swiper-pag');
        
        // Initialize slider
        new Swiper(swiper, { // eslint-disable-line no-new
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            spaceBetween: 40,
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
            pagination: {
                el: pag,
            },
        });
    }
}
