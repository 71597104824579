import Swiper from 'swiper';
import { events, emitEvent } from '../utilities/custom-events';

const ANIMATION_DURATION = 300;
const randomSign = () => (Math.round(Math.random()) ? 1 : -1);

export default class {
    constructor({
        id,
        sliderHandle,
        milestoneHandle,
        navHandle,
        yearsHandle,
        navButtonHandle,
        menuHandle,
        slideActiveClass,
        activeClass = 'is-active',
        menuActiveClass = 'menu-is-active',
    }) {
        const el = document.getElementById(id);
        const background = el.querySelector('em > div');
        const slider = el.querySelector(sliderHandle);
        const milestones = el.querySelectorAll(milestoneHandle);
        const nav = el.querySelector(navHandle);
        const years = nav.querySelector(yearsHandle);
        const [prev, menuTrigger, next] = nav.querySelectorAll(navButtonHandle);
        const menu = el.querySelector(menuHandle);
        const menuClose = menu.querySelector('h3 + ul + button');
        const decades = menu.querySelectorAll('h3 + ul > li');
        const decadeTriggers = menu.querySelectorAll('h3 + ul > li > button');
        const decadeCloses = menu.querySelectorAll('h3 + ul > li > div > h4 > button');
        const decadeMilestones = menu.querySelectorAll('h3 + ul > li > div > ul > li > button');
        const sectionSlider = new Swiper(slider, {
            slidesPerView: 'auto',
            direction: 'vertical',
            slideActiveClass,
        });
        // Function to move background image to random center position
        const moveBackground = () => {
            const s = Math.random() * 0.5;
            const x = Math.ceil(Math.random() * 50 * s) * randomSign();
            const y = Math.ceil(Math.random() * 50 * s) * randomSign();

            background.style.transform = `scale(${1 + s}) translate(${x}%, ${y}%)`;
        };

        let step = 0;

        sectionSlider.on('slideChange', () => {
            step = sectionSlider.realIndex;

            const year = years.children[step];

            years.style.transform = `translateX(calc(50% - ${year.offsetLeft}px))`;
            years.children.forEach(y => { y.classList.toggle(activeClass, y === year); });
            if (background) {
                moveBackground();
            }
        });
        years.children.forEach((y, i) => {
            y.onclick = () => {
                sectionSlider.slideTo(i);
            };
        });
        prev.onclick = () => {
            const current = milestones[step];
            const cb = () => {
                step = Math.max(0, step - 1);
                sectionSlider.slidePrev();
            };

            prev.classList.add(activeClass);
            setTimeout(() => { prev.classList.remove(activeClass); }, ANIMATION_DURATION);

            if (current.getAttribute('data-slider')) {
                emitEvent(events.timelinePrev, { step, cb });

                return;
            }

            cb();
        };
        next.onclick = () => {
            const current = milestones[step];
            const cb = () => {
                step = Math.min(milestones.length - 1, step + 1);
                sectionSlider.slideNext();
            };

            next.classList.add(activeClass);
            setTimeout(() => { next.classList.remove(activeClass); }, ANIMATION_DURATION);

            if (current.getAttribute('data-slider')) {
                emitEvent(events.timelineNext, { step, cb });

                return;
            }

            cb();
        };
        menuTrigger.onclick = () => el.classList.add(menuActiveClass);
        menuClose.onclick = () => {
            decades.forEach(d => { d.classList.remove(activeClass); });
            el.classList.remove(menuActiveClass);
        };
        decadeTriggers.forEach((t, i) => {
            t.onclick = () => {
                decades[i].classList.add(activeClass);
            };
        });
        decadeCloses.forEach((c, i) => {
            c.onclick = () => {
                decades[i].classList.remove(activeClass);
            };
        });
        decadeMilestones.forEach(m => {
            m.onclick = () => {
                sectionSlider.slideTo(m.dataset.index);
                decades.forEach(d => { d.classList.remove(activeClass); });
                el.classList.remove(menuActiveClass);
            };
        });
    }
}
