import { injectMarkup } from '../utilities/components';
import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        resultsHandle,
        countHandle,
        totalHandle,
        unloadingClass,
        shared,
        state,
    }) {
        const el = document.getElementById(id);
        const forms = el.querySelectorAll('form');
        const options = Array.from(forms).reduce((opts, form) => (
            opts.concat(Array.from(form.querySelectorAll('[type="radio"]')))
        ), []);
        const results = el.querySelector(resultsHandle);
        const count = el.querySelector(countHandle);
        const total = el.querySelector(totalHandle);

        // State variables
        let query = options
            .filter(option => option.checked)
            .reduce((obj, option) => ({ [option.name]: option.value, ...obj }), {});

        // Helper funcitons
        function fetchBlogs(storeState = true) {
            function scrollCb() {
                const hState = storeState
                    ? { url: window.location.pathname, query }
                    : null;

                function resCb(res) {
                    const { count: c, total: t, markup } = JSON.parse(res);

                    emitEvent(events.stopLoader);
                    injectMarkup(results, markup);
                    el.classList.remove(unloadingClass);
                    // Enable AJAX pagination
                    Array.from(results.querySelectorAll('nav a'))
                        .forEach(link => link.addEventListener('click', handlePage));

                    // Get count to update results heading
                    count.innerHTML = c;
                    total.innerHTML = t;
                }

                emitEvent(events.startLoader);
                get({ url: shared, query, state: hState, cb: resCb });
            }

            el.classList.add(unloadingClass);
            scrollTop(forms[0], state.navHeight, scrollCb);
        }

        // Event handler functions
        function handlePage(e) {
            e.preventDefault();

            query = {
                ...query,
                page: e.currentTarget.getAttribute('data-page'),
            };

            fetchBlogs();
        }
        function handleOption(e) {
            const option = e.currentTarget;

            // Set state variables
            query = {
                ...query,
                [option.name]: option.value,
                page: 1,
            };

            fetchBlogs();
        }
        function handlePopState(e) {
            if (!e.state) return;

            query = e.state.query || {};

            fetchBlogs(false);
        }

        // Add event listeners
        // Enable AJAX pagination
        Array.from(results.querySelectorAll('nav a'))
            .forEach(link => link.addEventListener('click', handlePage));
        options.forEach(option => {
            option.addEventListener('change', handleOption);
        });
        if (window.history) window.addEventListener('popstate', handlePopState);


        // If a category filter is applied, scroll to results and bypass featured articles
        if (window.location.search !== '') {
            scrollTop(forms[0], state.navHeight);
        }
    }
}
