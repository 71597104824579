/**
 * Custom info window overlay for Google Maps
 * https://developers.google.com/maps/documentation/javascript/customoverlays
 */

import { injectMarkup } from '../utilities/components';

export default class {
    constructor({ position, content, activeClass, maps }) {
        this.position = position;
        this.content = content;
        this.activeClass = activeClass;
        this.maps = maps;
        this.div = null;
    }

    expand() {
        if (this.div === null) return;

        this.div.classList.add(this.activeClass);
    }

    contract() {
        if (this.div === null) return;

        this.div.classList.remove(this.activeClass);
    }

    onAdd() {
    // Create info window overlay
        this.div = document.createElement('div');
        this.div.classList.add('info-window');
        this.div.style.position = 'absolute';
        this.div.style.cursor = 'pointer';
        injectMarkup(this.div, this.content);

        // Place on map
        this.getPanes().overlayMouseTarget.appendChild(this.div);

        // Listen for hover events
        this.maps.event.addDomListener(this.div, 'mouseenter', () => { this.expand(); });
        this.maps.event.addDomListener(this.div, 'mouseleave', () => { this.contract(); });
    }

    draw() {
        const { x, y } = this.getProjection().fromLatLngToDivPixel(this.position);

        this.div.style.left = `${x}px`;
        this.div.style.top = `${y}px`;
    }

    onRemove() {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
    }
}
