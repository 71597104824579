import { scrollBottom } from '../utilities/scroll';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const scroll = el.querySelector('button');

        // Event handler functions
        function handleScroll() {
            scrollBottom(el, -5); // Add offset to make sure adjacent navigation elements fix
        }

        // Add event listeners
        scroll.addEventListener('click', handleScroll);
    }
}
