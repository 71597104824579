import { scrollTo, scrollBottom } from '../utilities/scroll';

export default class {
    constructor({
        id,
        anchorId,
        state,
    }) {
        const el = document.getElementById(id);
        const scroll = el.querySelector('button');

        // Event handler functions
        function handleScroll() {
            if (anchorId) {
                scrollTo(anchorId, state.navHeight);
            } else {
                scrollBottom(el, state.navHeight);
            }
        }

        // Add event listeners
        if (scroll) scroll.addEventListener('click', handleScroll);
    }
}
