import { injectMarkup } from '../utilities/components';
import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        predicateHandle,
        countHandle,
        subjectHandle,
        resultsHandle,
        unloadingClass,
        subjects,
        shared,
        state,
    }) {
        const el = document.getElementById(id);
        const predicate = el.querySelector(predicateHandle);
        const count = el.querySelector(countHandle);
        const subject = el.querySelector(subjectHandle);
        const form = el.querySelector('form');
        const options = form.querySelectorAll('[type="radio"]');
        const results = el.querySelector(resultsHandle);

        // State variables
        let query = Array.from(options)
            .filter(option => option.checked)
            .reduce((obj, option) => ({ [option.name]: option.value, ...obj }), {});

        // Helper funcitons
        function loadSearch() {
            function scrollCb() {
                const hState = { url: window.location.pathname, query };

                function resCb(res) {
                    const { count: c, markup } = JSON.parse(res);

                    emitEvent(events.stopLoader);
                    injectMarkup(results, markup);
                    el.classList.remove(unloadingClass);

                    // Get count to update results heading
                    predicate.innerHTML = c === 1 ? 'is' : 'are';
                    count.innerHTML = c;
                    subject.innerHTML = c === 1 ? subjects[0] : subjects[1];
                }

                emitEvent(events.startLoader);
                get({ url: shared, query, state: hState, cb: resCb });
            }

            el.classList.add(unloadingClass);
            scrollTop(form, state.navHeight, scrollCb);
        }

        // Event handler functions
        function handleOption(e) {
            const option = e.currentTarget;

            // Set state variables
            query = {
                ...query,
                [option.name]: option.value,
            };

            loadSearch();
        }

        // Add event listeners
        Array.from(options).forEach(option => {
            option.addEventListener('change', handleOption);
        });
    }
}
