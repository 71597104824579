import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        activeClass = 'is-active',
    }) {
        const el = document.getElementById(id);
        const button = el.querySelector('button');

        const open = () => { el.classList.add(activeClass); };
        const close = () => { el.classList.remove(activeClass); };

        window.addEventListener(events.loadFancyModal, open);
        window.addEventListener(events.closeModal, close);
        button.onclick = () => { emitEvent(events.closeModal); };
    }
}
