import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';

export default class {
    constructor({
        id,
        preferenceHandle,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');

        // Event handler functions
        function handleSubmit(e) {
            e.preventDefault();

            // Emit event that user preference has been set and pass
            // callback function to catch enabled status of preference
            // to pass to preference controller via posted form data
            emitEvent(events.setPreference, {
                preferenceHandle,
                cb: enabled => {
                    const formData = new FormData(form);

                    formData.set('enabled', enabled);

                    post({ formData });
                },
            });
        }

        // Add event listeners
        form.addEventListener('submit', handleSubmit);
    }
}
