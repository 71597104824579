export default class {
    constructor({
        id,
        inViewClass = 'in-view',
        offset = '-20%',
    }) {
        const el = document.getElementById(id);
        const handleView = (entries, observer) => {
            entries
                .filter(({ isIntersecting }) => isIntersecting)
                .forEach(({ target }) => {
                    target.classList.add(inViewClass);
                    observer.unobserve(target);
                });
        };
        const spyObserver = new IntersectionObserver(handleView, {
            rootMargin: `0px 0px ${offset} 0px`,
        });

        spyObserver.observe(el);
    }
}
