import { events } from '../utilities/custom-events';

export default class {
    constructor({
        id,
    }) {
        // Params
        const el = document.getElementById(id);
        const holes = el.querySelectorAll('.golf-course-details__content');

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Details Nav
        const detailsNavIndicator = el.querySelector('.golf-course-details__details-nav__indicator');
        const detailsNavBtns = el.querySelectorAll('.golf-course-details__details-nav a');

        const setDetailsNavIndicator = btn => {
            detailsNavIndicator.style.width = `${btn.offsetWidth + 10}px`;
            detailsNavIndicator.style.left = `${btn.offsetLeft - 5}px`;
        };

        setDetailsNavIndicator(detailsNavBtns[0]);
        detailsNavBtns.forEach(btn => btn.addEventListener('mouseenter', e => setDetailsNavIndicator(e.target)));

        // Pop the modals when details nav buttons are clicked
        let holeIndex = 1;

        // First hide any currently active modals
        const modals = el.querySelectorAll('.golf-course-details__modal');
        const hideActiveModals = modals => {
            modals.forEach(modal => {
                modal.classList.remove('active');
            });
        };

        // Now pop the target modals
        detailsNavBtns[1].addEventListener('click', e => {
            hideActiveModals(modals);
            const targetHoleIndex = el.querySelector('.golf-course-details__button--active').textContent;
            holeIndex = parseInt(targetHoleIndex);
            const modalTees = el.querySelector(`#hole-${holeIndex} .golf-course-details__modal--tees`);
            modalTees.classList.add('active');
        });

        detailsNavBtns[2].addEventListener('click', e => {
            hideActiveModals(modals);
            const targetHoleIndex = el.querySelector('.golf-course-details__button--active').textContent;
            holeIndex = parseInt(targetHoleIndex);
            const modalTees = el.querySelector(`#hole-${holeIndex} .golf-course-details__modal--pro-advice`);
            modalTees.classList.add('active');
        });

        // Close button to close the modals
        const btnModalClose = el.querySelectorAll('.golf-course-details__modal-close');
        btnModalClose.forEach(btn => {
            btn.addEventListener('click', () => {
                hideActiveModals(modals);
            });
        });

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Hole Nav Toggle
        const holeNavWrapper = el.querySelector('.golf-course-details__hole-nav-wrapper');
        const holeNavToggleBtnWrapper = el.querySelector('.golf-course-details__hole-nav-toggle');
        const holeNavToggleBtns = el.querySelectorAll('.golf-course-details__hole-nav a');

        holeNavToggleBtns[0].addEventListener('click', () => {
            holeNavWrapper.classList.remove('golf-course-details__hole-nav-wrapper--front');
            holeNavWrapper.classList.add('golf-course-details__hole-nav-wrapper--back');
            holeNavToggleBtnWrapper.classList.remove('golf-course-details__hole-nav-toggle--front');
            holeNavToggleBtnWrapper.classList.add('golf-course-details__hole-nav-toggle--back');
        });

        holeNavToggleBtns[1].addEventListener('click', () => {
            holeNavWrapper.classList.remove('golf-course-details__hole-nav-wrapper--back');
            holeNavWrapper.classList.add('golf-course-details__hole-nav-wrapper--front');
            holeNavToggleBtnWrapper.classList.remove('golf-course-details__hole-nav-toggle--back');
            holeNavToggleBtnWrapper.classList.add('golf-course-details__hole-nav-toggle--front');
        });

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Setting the size of the hole videos so they fill the container
        function setVideosContainerSize() {
            const videoContainers = el.querySelectorAll('.golf-course-details__details-video iframe');
            const AR = 16 / 9;

            videoContainers.forEach((videoContainer) => {
                const { offsetWidth, offsetHeight } = videoContainer;
                const [w, h] = offsetWidth / offsetHeight > AR
                    ? [offsetWidth, offsetWidth / AR]
                    : [offsetHeight * AR, offsetHeight];

                videoContainer.style.width = `${w}px`;
                videoContainer.style.height = `${h}px`;
            });
        }

        window.addEventListener('resize', setVideosContainerSize, { passive: true });

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Hole Details Nav
        const holeNavBtns = el.querySelectorAll('.golf-course-details__hole-nav button');

        holeNavBtns.forEach((btn, index) => {
            btn.addEventListener('click', e => {
                // Hide current hole
                holes.forEach(hole => {
                    hole.style.display = 'none';
                });
                // Show target hole
                const targetHole = document.getElementById(`hole-${index + 1}`);
                targetHole.style.display = 'block';

                // Remove current active button style
                holeNavBtns.forEach(btn => {
                    btn.classList.remove('golf-course-details__button--active');
                });
                // Add target active button style
                e.target.classList.add('golf-course-details__button--active');

                // Slide details nav indicator to Overview and hide modals
                setDetailsNavIndicator(detailsNavBtns[0]);
                hideActiveModals(modals);
            });
        });

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Hole Videos Nav
        
        // Initialize the first hole video
        const firstHoleVideoId = holeNavBtns[0].dataset.videoId;

		// get the viewport width, as we only want the controls for desktop sizes
		const viewportWidth = window.innerWidth;
		const controls = viewportWidth > 1079 ? 1 : 0;

        const initializeVideo = (videoId) => {
            const videoDiv = document.createElement('div');
            videoDiv.setAttribute('id', 'holeVideoPlayer');
            const videoDivContainer = el.querySelector('.golf-course-details__details-video');
            videoDivContainer.appendChild(videoDiv);
            
            const options = {
                id: videoId,
                autoplay: 1,
                mute: 1,
                loop: 1,
                controls: controls,
            };

            const videoPlayer = new Vimeo.Player('holeVideoPlayer', options);
            videoPlayer.setVolume(0);

			// start playing the video again after the modal closes
			window.addEventListener(events.closeModal, () => {
				videoPlayer.play();
			});

            videoPlayer.on('play', function() {
                setVideosContainerSize();
            });
        }

        initializeVideo(firstHoleVideoId);

        // show videos when hole navigation buttons are clicked
        holeNavBtns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                // Destroy the current video
                const currentDiv = document.getElementById("holeVideoPlayer");
                currentDiv.remove();

                // Start playing the target video
                initializeVideo(e.target.dataset.videoId);
            });
        });

        // ****************************************************************************************************
        // ****************************************************************************************************

        // Spinny numbers!
        const spinNumbers = (holeNum, holePar, holeYards) => {
            // transition the hole number
            const numsHole = el.querySelector('.num-wrapper--hole .num-column');

            if (!numsHole) {
                return;
            }

            numsHole.style.transform = `translateY(-${holeNum * 106}px)`;

            // transition the par
            const numsPar = el.querySelector('.num-wrapper--par .num-column');
            numsPar.style.transform = `translateY(-${holePar * 106}px)`;
            
            // transition the yards
            const numsYards = el.querySelectorAll('.num-wrapper--yards .num-column');
            numsYards.forEach((numColumn, index) => {
                numColumn.style.transform = `translateY(-${holeYards[index] * 106}px)`;
            });
        }
        
        holeNavBtns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                spinNumbers(e.target.dataset.holeNum, e.target.dataset.holePar, e.target.dataset.holeYards);
            });
        });

        // Initialize first set of stats
        const firstHoleStats = holeNavBtns[0];
        spinNumbers(firstHoleStats.dataset.holeNum, firstHoleStats.dataset.holePar, firstHoleStats.dataset.holeYards);
    }
}
