import Swiper from 'swiper';

export default class {
    constructor({
        id,
        contHandle,
    }) {
    // Params
        const el = document.getElementById(id);
        const cont = el.querySelector(contHandle);
        const buttons = el.querySelectorAll('button');
        const prev = buttons[0];
        const next = buttons[1];

        // Initialize slider
        new Swiper(cont, { // eslint-disable-line no-new
            loop: true,
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
        });
    }
}
