import GoogleMapsLoader from 'google-maps';
import { googleMapsApiKey } from '../config';

GoogleMapsLoader.KEY = googleMapsApiKey;

export default class {
    constructor({
        id,
        coords,
    }) {
        const el = document.getElementById(id);

        GoogleMapsLoader.load(({ maps }) => { // eslint-disable-line no-undef
            const { lat, lng } = coords;
            const position = new maps.LatLng(parseFloat(lat, 10), parseFloat(lng, 10));
            const bounds = new maps.LatLngBounds();
            const map = new maps.Map(el.querySelector('div'), {
                center: new maps.LatLng(0, 0),
                zoom: 16,
                fullscreenControl: false,
                keyboardShortcuts: false,
                styles: [
                    {
                        featureType: 'poi',
                        stylers: [
                            { visibility: 'off' },
                        ],
                    },
                ],
            });
            const icon = {
                path: 'M0 200c-152.5 0-275 122.5-275 275s275 525 275 525 275-372.5 275-525-122.5-275-275-275zm0 400c-70 0-125-55-125-125s55-125 125-125 125 55 125 125-55 125-125 125z',
                fillColor: '#28724f',
                fillOpacity: 1,
                scale: 0.05,
                anchor: new maps.Point(0, 1000),

            };

            new maps.Marker({ // eslint-disable-line no-new
                position,
                map,
                icon,
            });

            bounds.extend(position);
            map.setCenter(position);
        });
        /* eslint-enable */
    }
}
