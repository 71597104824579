export default class {
    constructor({
        id,
        activeClass,
    }) {
    // Params
        const el = document.getElementById(id);
        const buttons = el.querySelectorAll('button');
        const prev = buttons[0];
        const next = buttons[1];
        const lists = el.querySelectorAll('ul');
        const images = lists[0].querySelectorAll('li');
        const steps = lists[1].querySelectorAll('li');
        const circles = el.querySelectorAll('circle');
        const progress = circles[1];

        let activeIndex = 0;

        // Helper functions
        function setActive() {
            Array.from(steps).forEach((step, i) => {
                step.classList.toggle(activeClass, i === activeIndex);
                images[i].classList.toggle(activeClass, i === activeIndex);
            });

            progress.style.strokeDasharray = `${((activeIndex + 1) / steps.length) * 100.48} 100.48`;
        }

        // Event handler functions
        function handlePrev() {
            activeIndex--;

            // return if needed
            if (activeIndex < 0) {
                activeIndex = steps.length - 1;
            }

            // Set new step
            setActive();
        }
        function handleNext() {
            activeIndex++;

            // return if needed
            if (activeIndex > steps.length - 1) {
                activeIndex = 0;
            }

            // Set new step
            setActive();
        }

        // Add event listeners
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
    }
}
