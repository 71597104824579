export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const toggle = el.querySelector('[type="checkbox"]');
        const options = el.querySelectorAll('[type="radio"]');

        // Event handler functions
        const handleChanges = Array.from(options).map(option => () => {
            const toggleLabel = el.querySelector(`[for="${toggle.id}"]`);
            const optionLabel = el.querySelector(`[for="${option.id}"]`);

            toggle.checked = false; // Close dropdown
            toggleLabel.querySelector('span').textContent = optionLabel.textContent;
        });

        // Add event listeners
        Array.from(options).forEach((option, i) => {
            option.addEventListener('change', handleChanges[i]);
            // Need extra click listener to close dropdown if user clicks on already selected option
            option.addEventListener('click', handleChanges[i]);
        });
    }
}
