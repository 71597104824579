import { injectMarkup } from '../utilities/components';
import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';
import { spyNav } from '../utilities/spy';

export default class {
    constructor({
        id,
        fixHandle,
        filtersToggleHandle,
        formHandle,
        resultsHandle,
        saveSearchHandle,
        activeClass,
        fixedClass,
        emptyClass,
        mapVisibleClass,
        unloadingClass,
        agent = '',
        page,
        listingType,
        kiosk,
    }) {
        const el = document.getElementById(id);
        const fix = el.querySelector(fixHandle);
        const filtersToggle = el.querySelector(filtersToggleHandle);
        const form = el.querySelector(formHandle);
        const toggles = form.querySelectorAll('[type="checkbox"]');
        const options = form.querySelectorAll('[type="radio"]');
        const results = el.querySelector(resultsHandle);
        const saveSearch = el.querySelector(saveSearchHandle);

        // State variables
        let query = Array.from(options)
            .filter(option => option.checked)
            .reduce((obj, option) => ({
                [option.name]: option.value, ...obj,
            }), { agent, page, listingType });

        // Helper functions
        function fetchProperties(storeState = true) {
            function scrollCb() {
                const url = '/shared/property-listings';
                const state = storeState
                    ? { url: window.location.pathname, query }
                    : null;

                function reqCb(res) {
                    const { markup, isFiltered } = JSON.parse(res);

                    // Show save search button for filtered results set
                    saveSearch.classList.toggle(activeClass, isFiltered);

                    emitEvent(events.stopLoader);
                    injectMarkup(results, markup);
                    el.classList.remove(unloadingClass);
                    // Enable AJAX pagination
                    Array.from(results.querySelectorAll('nav a'))
                        .forEach(link => link.addEventListener('click', handlePage));
                    // Enable AJAX order filtering
                    Array.from(results.querySelectorAll('[type="radio"]'))
                        .forEach(option => option.addEventListener('change', handleOption));
                    // Reset map markers
                    emitEvent(events.populateMap, {
                        properties: results.querySelectorAll('li'),
                        cb: notEmpty => el.classList.toggle(emptyClass, !notEmpty),
                    });
                }

                emitEvent(events.startLoader);
                get({
                    url,
                    // Pass preference into ajax query for use in rendered component markup
                    query: { preferenceHandle: mapVisibleClass, kiosk, ...query },
                    state,
                    cb: reqCb,
                });
            }

            el.classList.add(unloadingClass);
            scrollTop(fix, -5, scrollCb); // Add offset to make sure filters fix to top
            // Close all other dropdowns
            Array.from(toggles).forEach(toggle => { toggle.checked = false; });
            // Close mobile filters
            filtersToggle.checked = false;
        }

        // Event handler functions
        function handleSetPreference(e) {
            const { preferenceHandle, cb = null } = e.detail;

            // Return status of preference via state encoded in class name
            const enabled = el.classList.toggle(preferenceHandle);

            if (cb) {
                cb(enabled);
            }
        }
        function handleSpy(fixed) {
            emitEvent(fixed ? events.hideHeader : events.showHeader);
        }
        function handlePage(e) {
            e.preventDefault();

            query = {
                ...query,
                page: e.currentTarget.getAttribute('data-page'),
            };

            fetchProperties();
        }
        function handleOption(e) {
            const option = e.currentTarget;

            // Set state variables
            query = {
                ...query,
                [option.name]: option.value,
                page: 1,
            };

            fetchProperties();
        }
        function handlePopState(e) {
            if (!e.state) return;

            query = e.state.query || {};

            fetchProperties(false);
        }

        // Add event listeners
        window.addEventListener(events.setPreference, handleSetPreference);
        spyNav({ el, nav: fix, fixedClass, cb: handleSpy });
        // Enable AJAX pagination
        Array.from(results.querySelectorAll('nav a'))
            .forEach(link => link.addEventListener('click', handlePage));
        // Enable AJAX order filtering
        Array.from(results.querySelectorAll('[type="radio"]'))
            .forEach(option => option.addEventListener('change', handleOption));
        Array.from(options).forEach(option => {
            option.addEventListener('change', handleOption);
        });
        if (window.history) window.addEventListener('popstate', handlePopState);

        // Initialize map
        emitEvent(events.populateMap, {
            properties: results.querySelectorAll('li'),
            cb: notEmpty => el.classList.toggle(emptyClass, !notEmpty),
        });
    }
}
