// This is for the portion of the plan-card.twig that's supposed to act like the gallery component on the wedding page.
// Copy pasted galleries.js for the time being as a guide.

import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        lightboxHandle,
        activeClass,
    }) {

        const el = document.getElementById(id);
        const trigger = el.querySelector('.trigger');
        const lightbox = el.querySelector(lightboxHandle);
        let images = lightbox.querySelectorAll('img');
        const controls = lightbox.querySelectorAll('button');
        const prev = controls[0];
        const next = controls[1];
        const close = controls[2];

        let activeIndex = 0;

        // Event handler functions
        function handlePrev() {
            images[activeIndex].classList.remove(activeClass);
            activeIndex--;
            if (activeIndex < 0) {
                activeIndex = images.length - 1;
            }
            images[activeIndex].classList.add(activeClass);
        }
        function handleNext() {
            images[activeIndex].classList.remove(activeClass);
            activeIndex++;
            if (activeIndex > images.length - 1) {
                activeIndex = 0;
            }
            images[activeIndex].classList.add(activeClass);
        }
        function handleClose() {
            lightbox.classList.remove(activeClass);
            images = null;
            activeIndex = 0;
            emitEvent(events.hideOverlay);
        }

        function handleTrigger(e) {
            lightbox.classList.add(activeClass);
            images[activeIndex].classList.add(activeClass);
            emitEvent(events.showOverlay);
        }

        // Add event listeners
        if (trigger) {
            trigger.addEventListener('click', handleTrigger);
        }
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
        close.addEventListener('click', handleClose);
    }
}
