import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const error = form.querySelector('p');
        const cancel = form.querySelector('[type="button"]');

        function handleSubmit(e) {
            e.preventDefault();

            const formData = new FormData(form);

            function cb(res) {
                const json = JSON.parse(res);

                if (!json.success) {
                    error.innerText = json.message;

                    return;
                }

                // Disable current form
                form.removeEventListener('submit', handleSubmit);
                form.remove();

                // Show success message
                el.insertAdjacentHTML('beforeend', `<p>${json.message}</p>`);
            }

            post({ formData, cb });
        }
        function handleCancel() {
            emitEvent(events.closeModal);
        }

        form.addEventListener('submit', handleSubmit);
        cancel.addEventListener('click', handleCancel);
    }
}
