import { events, emitEvent } from '../utilities/custom-events';
import { spyNav } from '../utilities/spy';

export default class {
    constructor({
        id,
        progressHandle,
        fixedClass,
    }) {
        const el = document.getElementById(id);
        const progress = el.querySelector(progressHandle);

        // Event handler functions
        function handleSpy(fixed) {
            emitEvent(fixed ? events.hideHeader : events.showHeader);
        }
        function handleScroll() {
            const width = (window.pageYOffset + window.innerHeight) / document.body.offsetHeight;

            progress.style.transform = `scaleX(${width})`;
        }

        // Add event listeners
        spyNav({ el, fixedClass, cb: handleSpy });
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);


        // Initialize
        handleScroll();
    }
}
