import Masonry from 'masonry-layout';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const highlights = el.querySelector('ul');
        const images = highlights.querySelectorAll('img');

        const grid = new Masonry(highlights);

        function handleImage() {
            grid.layout();
        }

        Array.from(images).forEach(image => {
            image.addEventListener('load', handleImage);
        });
    }
}
