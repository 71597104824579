export default class {
    constructor({
        id,
        backgroundImageHandle,
    }) {
        const el = document.getElementById(id);
        const image = el.querySelector(backgroundImageHandle);

        function handleScroll() {
            const { top } = el.getBoundingClientRect();
            const halfHeight = window.innerHeight / 2;

            image.style.transform = `scale(1.2) translateY(${(top - halfHeight) * 0.05}px)`;
        }

        window.addEventListener('scroll', handleScroll);
    }
}
