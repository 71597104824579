export const events = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    loadRoute: 'load-route',
    unloadRoute: 'unload-route',
    showHeader: 'show-header',
    hideHeader: 'hide-header',
    closeHeader: 'close-header',
    showOverlay: 'show-overlay',
    hideOverlay: 'hide-overlay',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    loadFancyModal: 'load-fancy-modal',
    startLoader: 'start-loader',
    stopLoader: 'stop-loader',
    filterForm: 'filter-form',
    showFormErrors: 'show-form-errors',
    formSubmitted: 'form-submitted',
    setPreference: 'set-preference',
    populateMap: 'populate-map',
    timelinePrev: 'timeline-prev',
    timelineNext: 'timeline-next',
};

/**
 * Emit event - wrapper around CustomEvent API
 * @param {string} eventHandle
 * @param {object} eventDetails
 */
export function emitEvent(eventHandle, eventDetails) {
    const event = new CustomEvent(eventHandle, { detail: eventDetails });

    window.dispatchEvent(event);
}
