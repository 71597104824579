import { syncDates } from '../utilities/forms';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const dates = el.querySelectorAll('[type="date"]');

        if (dates.length === 2) {
            syncDates(...dates);
        }
    }
}
