// This function allows users to drop a data attribute in a tags to place the Amobee pixel for Awareness - see events.js for interest retargeting
// NOTE: In order for this to function in redactor fields, HTML Purifier will need to be turned off

// TODO add functionality that allows for dynamic pixel definition

export function enableAmobeeAwarenessPixels() {

    const amobeeTracker = 'https://r.turn.com/r/beacon?b2=U5hOQNCNFt_LIoKaVK-ohRidhxx8gz33DETLFcOQr5Z00MS-LPU4iuJFQppHqfFoDH5Sk2m4S2HBVaP8UiB5ZQ&cid=';

    // Elements and class variables
    const links = document.querySelectorAll('[data-amobee-awareness]');

    // Append tracking pixel to body
    function handleTracking() {
        
        const img = `
            <img
            height="1" width="1" alt=""
            style="position:absolute;border-style:none;"
            src="${amobeeTracker}" />
        `;
        document.body.insertAdjacentHTML('beforeend', img);
    }

    Array.from(links)
    .forEach(link => link.addEventListener('click' , handleTracking));
}
