export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const images = el.querySelectorAll('img');
        const menu = el.querySelector('.book-your-stay__selector-menu');
        const trigger = menu.querySelector('button');
        const items = menu.querySelectorAll('li');
        const cta = el.querySelector('a');
        const setWidth = width => { trigger.style.width = `${width + 48}px`; };
        const setActiveImage = item => {
            images.forEach(img => { img.classList.toggle('is-active', img.dataset.img === item.dataset.img); });
        };
        let firstClick = false;

        trigger.onmouseenter = () => { setActiveImage(trigger.querySelector('span')); };
        trigger.onclick = () => {
            const maxWidth = [trigger, ...items].reduce((max, item) => Math.max(max, item.querySelector('span').offsetWidth), 0);
            const width = menu.classList.toggle('is-active')
                ? maxWidth
                : trigger.querySelector('span').offsetWidth;
            firstClick && cta.classList.toggle('is-active');
            firstClick = true;
            setWidth(width);
        };
        items.forEach(item => {
            item.onmouseenter = () => { setActiveImage(item.querySelector('span')); };
            item.onclick = () => {
                const text = item.innerHTML;
                const width = item.querySelector('span').offsetWidth;
                const { url } = item.querySelector('span').dataset;

                item.innerHTML = trigger.innerHTML;
                trigger.innerHTML = text;
                menu.classList.remove('is-active');
                cta.classList.add('is-active');
                cta.setAttribute('href', url);
                setWidth(width);
            };
        });

        setWidth(trigger.querySelector('span').offsetWidth);
    }
}
