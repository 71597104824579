import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const main = document.querySelector('main');

        const handleScroll = debounce(() => {
            const top = main.getBoundingClientRect().top;
            const bottom = top + main.offsetHeight;

            el.classList.toggle(activeClass, top < 0 && bottom - window.innerHeight > 0);
        }, 100);

        window.addEventListener('scroll', handleScroll);
    }
}
