import debounce from '../utilities/debouncer';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        triggerHandle,
        contHandle,
        topHandle,
        visibleClass,
    }) {
        const el = document.getElementById(id);
        const trigger = el.querySelector(triggerHandle);
        const cont = el.querySelector(contHandle);
        const top = el.querySelector(topHandle);

        // Event handler functions
        const handleTriggerObserver = debounce(entries => {
            // Need offset threshold to make sure footer triggers
            if (entries[0].boundingClientRect.top < window.innerHeight + 5) {
                el.style.paddingBottom = `${cont.offsetHeight}px`;
                el.classList.add(visibleClass);
            } else {
                el.style.paddingBottom = 0;
                el.classList.remove(visibleClass);
            }
        }, 500);
        function handleTop() {
            scrollTop();
        }

        // Enable event listeners
        const triggerObserver = new IntersectionObserver(handleTriggerObserver, {
            rootMargin: '0px 0px 5px 0px', // Set threshold to ensure trigger gets in view
        });
        triggerObserver.observe(trigger);
        if (top) {
            top.addEventListener('click', handleTop);
        }
    }
}
