import Swiper from 'swiper';
import { events } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        primaryHandle,
        secondaryHandle,
        counterHandle,
        pageHandle,
        index,
    }) {
        const el = document.getElementById(id);
        const primary = el.querySelector(primaryHandle);
        const secondary = el.querySelector(secondaryHandle);
        const counter = el.querySelector(counterHandle);
        const paginate = el.querySelector(pageHandle);
        const primarySlider = new Swiper(primary, {
            slidesPerView: 'auto',
            pagination: {
                el: paginate,
            },
        });
        const secondarySlider = new Swiper(secondary, {
            slidesPerView: 'auto',
        });
        const setIntroSlide = slider => {
            counter.innerHTML = `<i>${slider.realIndex + 1}</i> / ${slider.slides.length}`;
        };

        window.addEventListener(events.timelinePrev, ({ detail }) => {
            const { step, cb } = detail;

            if (step !== index) {
                return;
            }

            if (primarySlider.realIndex === 0) {
                cb();

                return;
            }

            primarySlider.slidePrev();
        });
        window.addEventListener(events.timelineNext, ({ detail }) => {
            const { step, cb } = detail;

            if (step !== index) {
                return;
            }

            if (primarySlider.realIndex === primarySlider.slides.length - 1) {
                cb();

                return;
            }

            primarySlider.slideNext();
        });
        primarySlider.controller.control = secondarySlider;
        secondarySlider.controller.control = primarySlider;
        primarySlider.on('slideChange', () => { setIntroSlide(primarySlider); });

        setIntroSlide(primarySlider);
    }
}
