import { events, emitEvent } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';
import { spyNav } from '../utilities/spy';

export default class {
    constructor({
        id,
        activeClass,
        fixedClass,
    }) {
        const el = document.getElementById(id);
        const toggles = el.querySelectorAll('button');

        // Helper functions
        function close() {
            Array.from(toggles).forEach(toggle => {
                toggle.classList.remove(activeClass);
            });

            document.body.removeEventListener('click', close);
        }

        // Event handler functions
        function handleSpy(fixed) {
            emitEvent(fixed ? events.hideHeader : events.showHeader);
        }
        function handleToggle(e) {
            const toggle = e.currentTarget;

            // Don't want to fire close from body click on open of nav
            if (!toggle.classList.contains(activeClass)) {
                e.stopPropagation();
            }

            function cb() {
                if (toggle.classList.toggle(activeClass)) {
                    document.body.addEventListener('click', close);
                }
            }

            if (el.getBoundingClientRect().top > 0) {
                scrollTop(el, -5, cb); // Offset by 1 to make sure logo shrinks
            } else {
                cb();
            }
        }

        // Add event listeners
        spyNav({ el, fixedClass, cb: handleSpy });
        Array.from(toggles).forEach(toggle => {
            toggle.addEventListener('click', handleToggle);
        });
    }
}
