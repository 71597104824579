import { post } from '../utilities/router';

export default class {
    constructor({
        id,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');

        function handleSubmit(e) {
            e.preventDefault();

            const formData = new FormData(form);

            function cb() {
                el.classList.remove(activeClass);
            }

            post({ formData, cb });
        }

        form.addEventListener('submit', handleSubmit);
    }
}
