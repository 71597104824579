const clamp = (num, min, max) => (num <= min // eslint-disable-line no-nested-ternary
    ? min
    : num >= max
        ? max
        : num);

const SCROLL_FACTOR = 0.666; // The devil's scroll

export default class {
    constructor({
        id,
        callapsedClass = 'is-collapsed',
    }) {
        const el = document.getElementById(id);
        const back = el.querySelector('nav > a');
        const [collapse, move] = el.querySelectorAll('nav ~ button');
        const [scrollUp, scrollDown] = el.querySelectorAll('nav > button');
        const { x: x0, y: y0, width, height } = el.getBoundingClientRect();
        const [minX, maxX, minY, maxY] = [
            -1 * x0,
            window.innerWidth - x0 - width,
            -1 * y0,
            window.innerHeight - y0 - height,
        ];

        // let clickOffsetX = 0;
        let clickOffsetY = 0;

        function handlePointerMove({ clientY: y }) {
            const translateY = clamp(y - y0 - clickOffsetY, minY, maxY);

            el.style.transform = `translateY(${translateY}px)`;
        }
        // function handleTouchMove(e) {
        //     const { clientY: y } = e.targetTouches[0];
        //     const translateY = clamp(y - y0 - clickOffsetY, minY, maxY);

        //     el.style.transform = `translateY(${translateY}px)`;
        // }
        function handleScroll(top) {
            scrollUp.disabled = top <= 0;
            scrollDown.disabled = top + window.innerHeight >= document.body.offsetHeight;
            window.scrollTo({ top, behavior: 'smooth' });
        }

        collapse.onclick = ({ clientY: y }) => {
            const { left, top } = el.getBoundingClientRect();

            clickOffsetY = y - top;

            const translateX = window.innerWidth - left - move.offsetWidth;
            const translateY = clamp(y - y0 - clickOffsetY, minY, maxY);

            el.classList.toggle(callapsedClass);
            el.style.transform = `translate(${translateX}px, ${translateY}px)`;
        };
        move.onpointerdown = ({ clientY: y }) => {
            clickOffsetY = y - el.getBoundingClientRect().top;

            el.style.transition = 'none';
            window.addEventListener('pointermove', handlePointerMove, { passive: true });
        };
        // move.ontouchstart = e => {
        //     const { clientY: y } = e.targetTouches[0];

        //     clickOffsetY = y - el.getBoundingClientRect().top;

        //     window.addEventListener('touchmove', handleTouchMove, { passive: true });
        // };
        window.onpointerup = () => {
            el.style.removeProperty('transition');
            window.removeEventListener('pointermove', handlePointerMove);
        };
        // window.ontouchend = () => {
        //     window.removeEventListener('touchmove', handleTouchMove);
        // };
        scrollUp.onclick = () => {
            const top = window.pageYOffset - window.innerHeight * SCROLL_FACTOR;

            handleScroll(top);
        };
        scrollDown.onclick = () => {
            const top = window.pageYOffset + window.innerHeight * SCROLL_FACTOR;

            handleScroll(top);
        };

        if (window.history.length > 1) {
            back.onclick = () => {
                window.history.back();
            };
        }
    }
}
