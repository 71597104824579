export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);

        // pull this out the stack to give Swiper a chance to apply the active slide class
        setTimeout(() => {
            const activeSlide = el.closest('.swiper-slide-active');

            if (activeSlide) {
                const slidesContainer = document.querySelector('.dots-carousel--quote-callout');

                const btnPrev = slidesContainer.querySelector('.dots-carousel__prev');
                const btnNext = slidesContainer.querySelector('.dots-carousel__next');
                const slides = slidesContainer.querySelectorAll('.swiper-slide');

                const addAnimationClass = () => {
                    slides.forEach((slide) => {
                        // 1. remove animated class from all slides
                        slide.querySelector('.quote-callout').classList.remove('quote-callout--animated');
                        if (slide.classList.contains('swiper-slide-active')) {
                            // 2. add it to the active slide to trigger the animatoins
                            slide.querySelector('.quote-callout').classList.add('quote-callout--animated');
                            // 3. remove it again after the transition so that when a mobile user swipes the animated class won't mess stuff up
                            setTimeout(() => {
                                slide.querySelector('.quote-callout').classList.remove('quote-callout--animated');
                            }, 2500)
                        }
                    });
                }

                btnPrev && btnPrev.addEventListener('click', addAnimationClass);
                btnNext && btnNext.addEventListener('click', addAnimationClass);
            }
        }, 0);
    }
}
