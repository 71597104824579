import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';

export default class {
    constructor({
        id,
        shared,
        loginUrl,
        autoTrigger,
    }) {
        const el = document.getElementById(id);
        const link = el.querySelector('a');
        const button = el.querySelector('button');

        // Event handler functions
        function handleLink(e) {
            e.preventDefault();

            // Dynamically update redirect URL to make sure it has filters
            const { href } = window.location;
            const redirect = `${href}${href.includes('?') ? '&' : '?'}autoTrigger=true`;

            window.location = `${loginUrl}?redirect=${encodeURIComponent(redirect)}`;
        }
        function handleButton() {
            const url = `${shared}${window.location.search}`;

            // Open save search form in modal
            function cb(res) {
                const { markup } = JSON.parse(res);

                emitEvent(events.stopLoader);
                emitEvent(events.loadModal, {
                    markup,
                    full: true,
                    size: 'md',
                });
            }

            emitEvent(events.startLoader);
            get({ url, cb });
        }

        // Add event listeners
        if (link) {
            link.addEventListener('click', handleLink);
        }
        if (button) {
            button.addEventListener('click', handleButton);
        }

        if (button && autoTrigger) {
            handleButton();
        }
    }
}
