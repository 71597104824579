import KioskBoard from 'kioskboard';
import { events } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        inputHandle = 'kiosk-contact-input',
        activeClass = 'is-active',
        successClass = 'is-success',
    }) {
        const el = document.getElementById(id);
        const trigger = el.querySelector('button');
        const modal = el.querySelector('div');
        const closes = modal.querySelectorAll('button[type="button"]');
        const inputs = modal.querySelectorAll('form [name]:not([type="hidden"]):not(select):not([type="checkbox"]):not([type="radio"])');

        trigger.onclick = () => {
            el.classList.add(activeClass);
        };
        closes.forEach(c => {
            c.onclick = () => {
                if (el.classList.contains(successClass)) {
                    window.location.reload();
                }

                el.classList.remove(activeClass);
            };
        });

        inputs.forEach(input => {
            input.classList.add(inputHandle);
            input.setAttribute('data-kioskboard-specialcharacters', true);
        });
        KioskBoard.init({
            keysArrayOfObjects: [
                {
                    0: 'Q',
                    1: 'W',
                    2: 'E',
                    3: 'R',
                    4: 'T',
                    5: 'Y',
                    6: 'U',
                    7: 'I',
                    8: 'O',
                    9: 'P',
                },
                {
                    0: 'A',
                    1: 'S',
                    2: 'D',
                    3: 'F',
                    4: 'G',
                    5: 'H',
                    6: 'J',
                    7: 'K',
                    8: 'L',
                },
                {
                    0: 'Z',
                    1: 'X',
                    2: 'C',
                    3: 'V',
                    4: 'B',
                    5: 'N',
                    6: 'M',
                },
            ],
            keysNumpadArrayOfNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
            keysFontSize: '48px',
            keysIconSize: '54px',
            capsLockActive: false,
            autoScroll: false,
            allowRealKeyboard: true,
            keysEnterCallback: () => {
                modal.querySelector('form [type="submit"]').click();
            },
        });
        KioskBoard.run(`.${inputHandle}`);

        window.addEventListener(events.formSubmitted, () => {
            el.classList.add(successClass);
        });
    }
}
