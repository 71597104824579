import Swiper from 'swiper';

export default class {
    constructor({
        id,
        contHandle,
        prevHandle,
        nextHandle,
        pageHandle,
        transitionDelay,
        totalSlides,
        autoHeight,
    }) {
    // Params
        const el = document.getElementById(id);
        const cont = el.querySelector(contHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const page = el.querySelector(pageHandle);

        // Initialize slider
        const mySwiper = new Swiper(cont, { // eslint-disable-line no-new
            loop: true,
            effect: 'fade',
            fade: { crossFade: true },
            pagination: {
                el: page,
            },
            autoHeight: autoHeight,
        });

        prev.addEventListener('click', () => {
            setTimeout(() => {
                mySwiper.slidePrev();
            }, transitionDelay);
        });

        next.addEventListener('click', () => {
            setTimeout(() => {
                mySwiper.slideNext();
            }, transitionDelay);
        });
    }
}
