export default class {
    constructor({
        id,
        carouselId,
    }) {
        const el = document.getElementById(id);
        const vidLink = el.querySelector('.page-card__asset-cont');
        const video = el.querySelector('video');

        if (video) {
            vidLink.addEventListener('mouseenter', () => { video.play(); });
            vidLink.addEventListener('mouseleave', () => {
                video.pause();
                // Page cards embedded in a Swiper slider will be duplicated and
                // need to be kept in sync w/ each other.
                document.querySelectorAll(`#${id} video`).forEach(v => {
                    v.currentTime = video.currentTime; // Set time to match
                    v.play().then(() => { v.pause(); }); // Play video to match time, then pause
                });
            });
        }
    }
}
