import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { scrollTo } from '../utilities/scroll';

export default class {
    constructor({
        id,
        action,
        trackingUrl,
        state,
        player,
    }) {
        if (!action) return;

        // Elements and class variables
        const el = document.getElementById(id);

        // Helper functions
        function handleAnchor(anchor) {
            const offset = state.navHeight || 100; // set default in case using no-heder layout

            scrollTo(anchor, offset - 5); // Add threshold to offset to help anchor navs
        }
        function handleModal(url, query = {}, fancy = false) {
            function cb(res) {
                const { markup } = JSON.parse(res);
                const full = true;
                const position = el.getAttribute('data-modal-position');
                const size = el.getAttribute('data-modal-size');

                emitEvent(events.stopLoader);
                emitEvent(events.loadModal, { markup, full, position, size, fancy });
            }

            emitEvent(events.startLoader);
            get({ url, query, cb });
        }
        function handleFancyModal(url, query = {}) {
            function cb(res) {
                const { markup } = JSON.parse(res);
                const full = true;
                const kiosk = el.getAttribute('data-modal-kiosk');
                const position = el.getAttribute('data-modal-position');
                const size = el.getAttribute('data-modal-size');

                emitEvent(events.stopLoader);
                emitEvent(events.loadFancyModal, { markup, full, kiosk, position, size });
            }

            emitEvent(events.startLoader);
            get({ url, query, cb });
        }
        function handleRoute(url) {
            emitEvent(events.closeHeader);
            emitEvent(events.startLoader);
            emitEvent(events.unloadRoute);

            window.location = url;
        }
        function handleExternal() {
            window.open(
                el.href,
                '_blank',
                'noopener',
            );
        }

        // Event handler functions
        function handleClick(e) {
            e.preventDefault();

            const url = el.href;

            switch (action) {
            case 'anchor': {
                const hash = url.split('#')[1];

                handleAnchor(hash);

                break;
            }
            case 'modal':
                handleModal(url);

                break;
            case 'video': {
                const videoId = el.getAttribute('data-video-id');

                handleModal('/shared/video', { videoId, player });

                break;
            }
            case 'fancyVideo': {
                const videoId = el.getAttribute('data-video-id');

                handleFancyModal('/shared/video', { videoId, fancyModal: true, player });

                break;
            }
            case 'route':
                handleRoute(url);

                break;
            case 'external':
                handleExternal(url);

                break;
            case 'noAction':
                // Don't do anything!

                break;
            default:
                break;
            }
        }
        function handleTracking() {
            // Append tracking pixel to body
            const img = `
        <img
          height="1" width="1" alt=""
          style="position:absolute;border-style:none;"
          src="${trackingUrl}" />
      `;
            document.body.insertAdjacentHTML('beforeend', img);
        }

        // Add event listeners
        el.addEventListener('click', handleClick);

        if (trackingUrl) {
            el.addEventListener('click', handleTracking);
        }
    }
}
