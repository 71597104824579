import GoogleMapsLoader from 'google-maps';
import { googleMapsApiKey } from '../config';
import debounce from '../utilities/debouncer';
import { events } from '../utilities/custom-events';
import InfoWindow from './info-window';

GoogleMapsLoader.KEY = googleMapsApiKey;

export default class {
    constructor({
        id,
        mapHandle,
        pinHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const map = el.querySelector(mapHandle);

        // State variables
        let gApi = null;
        let gMap = null;
        let gBounds = null;
        let markers = [];

        // Helper functions
        function setMarkers(properties) {
            if (properties.length === 0) {
                return false;
            }

            Array.from(properties).forEach(property => {
                const overview = property.innerHTML;
                const price = property.getAttribute('data-price');
                const lat = property.getAttribute('data-lat');
                const lng = property.getAttribute('data-lng');

                const position = new gApi.maps.LatLng(parseFloat(lat, 10), parseFloat(lng, 10));
                const infoWindow = new InfoWindow({
                    position,
                    content: `<div class="${pinHandle}"><span>${price}</span><div style="background-color: #fff">${overview}</div></div>`,
                    activeClass,
                    maps: gApi.maps,
                });

                markers.push(infoWindow);

                infoWindow.setMap(gMap);
                gBounds.extend(position);
                gMap.setCenter(position);

                property.addEventListener('mouseenter', () => {
                    gMap.panTo(position);
                    infoWindow.expand();
                });
                property.addEventListener('mouseleave', () => {
                    infoWindow.contract();
                });
            });

            // Fit bounds of map with all positions if multiple properties
            if (properties.length > 1) gMap.fitBounds(gBounds);

            return true;
        }

        // Event handler functions
        function handlePopulateMap(e) {
            const { properties, cb = null } = e.detail;

            // If map not ready to be populated, keep trying
            if (gMap === null) {
                setTimeout(handlePopulateMap.bind(this, e), 300);

                return;
            }

            // Clear any existing markers
            markers = markers
                .map(marker => marker.setMap(null))
                .filter(() => false);

            // Set new markers
            const notEmpty = setMarkers(properties);

            if (cb !== null) {
                cb(notEmpty);
            }
        }
        const handleResize = debounce(() => {
            gMap.fitBounds(gBounds);
        }, 500);

        // Add event listeners
        window.addEventListener(events.populateMap, handlePopulateMap);
        window.addEventListener(events.setPreference, handleResize);
        window.addEventListener('resize', handleResize);

        // Initialize
        /* eslint-disable */
        GoogleMapsLoader.load(g => {
            // Set state variables
            gApi = g;
            gBounds = new gApi.maps.LatLngBounds();
            gMap = new gApi.maps.Map(map, {
                center: new gApi.maps.LatLng(0, 0),
                zoom: 10,
                mapTypeControl: false,
				keyboardShortcuts: false,
                fullscreenControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                styles: [
                    {
                        "featureType": "poi",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }
                ]
            });

            // Dynamically extend InfoWindow class with OverlayView methods
            Object.setPrototypeOf(InfoWindow.prototype, gApi.maps.OverlayView.prototype);
        });
        /* eslint-enable */
    }
}
