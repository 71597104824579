// This function allows users to drop a data attribute in a tags to place the Amobee pixel for Action - Retargeting
// NOTE: In order for this to function in redactor fields, HTML Purifier will need to be turned off

// TODO add functionality that allows for dynamic pixel definition

export function enableAmobeePixels() {

    const amobeeTracker = 'https://r.turn.com/r/beacon?b2=rF2BBFLEAdtD00pZ9GsYeqcmZVmHk3Oce6ri5r0kcnt00MS-LPU4iuJFQppHqfFoV6xonj1q9vMus7_7auXfKg&cid=';

    // Elements and class variables
    const links = document.querySelectorAll('[data-amobee-tracker]');

    // Append tracking pixel to body
    function handleTracking() {
        
        const img = `
            <img
            height="1" width="1" alt=""
            style="position:absolute;border-style:none;"
            src="${amobeeTracker}" />
        `;
        document.body.insertAdjacentHTML('beforeend', img);
    }

        Array.from(links)
        .forEach(link => link.addEventListener('click' , handleTracking));
}
